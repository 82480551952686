import React from "react";
import { AiFillCheckSquare } from "react-icons/ai";

const RoomCancellation = () => {
  return (
    <div>
      <div className="services_cancel pt-5 ">
        <div className="container">
          <h4>Cancellation charges will apply as:</h4>
          <hr />
          <p>
            
            <AiFillCheckSquare size={25} color="#1f2b3b" /> All changes to
            reservations and any cancellations must be in writing and has to be
            made through corresponding email ID  hoteldorjilingreservation@gmail.com or 
             call at  17165003 / +975 77249630.
            <br /> <br />
            <b>Cancellation charges will apply as:</b>
           
            <br />
            <AiFillCheckSquare size={25} color="#1f2b3b" />
            100% room charge will be applicable if the room is not cancelled
            within 30 days prior to the arrival date in High Season and within
            15 days prior to the arrival date in Low Season.
            <br />
            <AiFillCheckSquare size={25} color="#1f2b3b" />
            No shows: 100% cancellation Fees
          </p>
        </div>
      </div>
    </div>
  );
};

export default RoomCancellation;
