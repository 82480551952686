import React from "react";
import KingDeluxeForm from "./Forms/KingDeluxeForm";
const BookKingDeluxe = () => {
  return (
    <div>
      <section>
        <div className="container overflow-hidden">
          <p>
            Room Booking for <br />
            <span className="h1 text-yellow"> 
              
              KING <span className="text-blue">DELUXE </span>
            </span>
          </p>
          <div class="row gx-5">
            <div class="col-lg-8 mb-4">
              <div class="p-3 border rounded ">
                <KingDeluxeForm />
              </div>
            </div>
            <div class="col">
              <div class="p-4 rooms_wrapper rounded">
              <h4>Facilities in the rooms:</h4>
              <hr />

              <table>
                <tr>
                  <td  className="border rounded p-2">FLAT SCREEN SMART TV [ 42INCH]</td>
                  <td  className="border rounded p-2" >Yes</td>
                </tr>
                <tr>
                  <td  className="border rounded p-2">KING SIZE [SQUARE MATTRESS]
6/6.5FTS </td>
                  <td  className="border rounded p-2">Yes</td>
                </tr>
                <tr>
                  <td  className="border rounded p-2">BATHROOM  </td>
                  <td  className="border rounded p-2">Yes</td>
                </tr>
                <tr>
                  <td  className="border rounded p-2">BATHTUB  </td>
                  <td  className="border rounded p-2">Yes</td>
                </tr>
                <tr>
                  <td  className="border rounded p-2">AIR CONDITIONING/HEATING </td>
                  <td  className="border rounded p-2">Yes</td>
                </tr>
                <tr>
                  <td  className="border rounded p-2">TEA/COFFEE STATIONS  </td>
                  <td  className="border rounded p-2">Yes</td>
                </tr>
                <tr>
                  <td  className="border rounded p-2">SEPARATE STUDY TABLE </td>
                  <td  className="border rounded p-2">Yes</td>
                </tr>
                <tr>
                  <td  className="border rounded p-2">MINI BAR  </td>
                  <td  className="border rounded p-2">Yes</td>
                </tr>
                <tr>
                  <td  className="border rounded p-2">SHOWER </td>
                  <td  className="border rounded p-2">Yes</td>
                </tr>
                <tr>
                  <td  className="border rounded p-2">TELEPHONE </td>
                  <td  className="border rounded p-2">Yes</td>
                </tr>
                <tr>
                  <td  className="border rounded p-2">HAIR DRYER  </td>
                  <td  className="border rounded p-2">Yes</td>
                </tr>
                <tr>
                  <td  className="border rounded p-2"> BATHROOM AMENITIES</td>
                  <td  className="border rounded p-2">Yes</td>
                </tr>
                <tr>
                  <td  className="border rounded p-2">INTERNET ACCESS </td>
                  <td  className="border rounded p-2">Yes</td>
                </tr>
                <tr>
                  <td  className="border rounded p-2"> PLUG TYPE</td>
                  <td  className="border rounded p-2">Yes</td>
                </tr>

                <tr>
                  <td  className="border rounded p-2"> TRIPLE BED OPTION</td>
                  <td  className="border rounded p-2">Yes</td>
                </tr>

                <tr>
                  <td  className="border rounded p-2"> DOUBLE BED OPTION</td>
                  <td  className="border rounded p-2">Yes</td>
                </tr>

                <tr>
                  <td  className="border rounded p-2"> WHEEL CHAIR ACCESSIBILITY</td>
                  <td  className="border rounded p-2">Yes</td>
                </tr>
              </table>

              </div>
            </div>
          </div>
        </div>

      </section>
    </div>
  );
};

export default BookKingDeluxe;
