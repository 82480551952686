import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { useFormik } from "formik";

const initialValues = {
  name: "",
  email: "",
  subject: "",
  message: "",
};

const ContactUsForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = () => {
    const data = new URLSearchParams();
    setIsLoading(true);
    data.append("name", name);
    data.append("message", message);
    data.append("email", email);
    data.append("subject", subject);
    data.append("receiver_email", process.env.REACT_APP_RECEIVER_MAIL);
    fetch(process.env.REACT_APP_MAIL_BASE_URL + "/gmailsmtp/index.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Access-Control-Request-Headers": "*",
        "Access-Control-Request-Method": "*",
      },
      body: data,
    })
      .then((res) => {
        setIsLoading(false);
        navigate("/contact-us-form/submitted");
      })
      .catch((e) => console.log(e));
  };

  return (
    <div>
      <p className="fw-bold text-white">
        {" "}
        We look forward to receiving your input
      </p>
      <p className="text-yellow">
        Kindly provide your contact
        details, and a member of our team will reach out to you soon.
      </p>
      <div class="mb-3">
        <p for="name" class="form-label text-white">
          Name <span className="fs-6"> (required)</span>
        </p>
        <input
          class="form-control"
          type="text"
          name="name"
          aria-label="default input example"
          onChange={(e) => setName(e.target.value)}
        />
      </div>

      <div class="mb-3">
        <p for="name" class="form-label text-white">
          Subject <span className="fs-6"> (required)</span>
        </p>
        <input
          class="form-control"
          type="text"
          aria-label="default input example"
          name="subject"
          onChange={(e) => setSubject(e.target.value)}
        />
      </div>

      <div class="mb-3">
        <p for="exampleFormControlInput1" class="form-label text-white">
          Email Address <span className="fs-6"> (required)</span>
        </p>
        <input
          type="email"
          class="form-control"
          id="exampleFormControlInput1"
          name="email"
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div class="mb-3">
        <p for="exampleFormControlTextarea1" class="form-label text-white">
          Message <span className="fs-6"> (required)</span>
        </p>
        <textarea
          class="form-control"
          id="exampleFormControlTextarea1"
          rows="3"
          name="message"
          onChange={(e) => setMessage(e.target.value)}
        ></textarea>
      </div>

      <div className="col-lg-6">
        <button
          onClick={handleSubmit}
          disabled={isLoading}
          className="main-btnl text-end"
        >
          {" "}
          {isLoading ? "Sending" : "Send"}
        </button>
      </div>
    </div>
  );
};

export default ContactUsForm;
