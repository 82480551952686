import React from "react";
import { Link } from "react-router-dom";
import { MdOutlineLocationOn } from "react-icons/md";
import { BsTelephoneInboundFill } from "react-icons/bs";
import { MdMarkEmailRead } from "react-icons/md";

import { BsArrowRightSquareFill } from "react-icons/bs";

const Footer = () => {
  return (
    <div>
      <div className="container-fluid bg-yellow py-5 ">
        <div className="container ">
          <div className="row">
            <div className="col-lg-2 text-center text-white">
              <img
                src={require("./../Img/Logo.png")}
                alt=""
                className="img-fluid p-3 rounded bg-white"
              />
              <br />
              <br />
              <h5 className="text-white">
                <span className="h3">Hotel Dorjiling</span> <br />
                <span className="fw-bold text-blue"> Hotel & Resort</span>
              </h5>
              <hr />
            </div>

            <div className="col-lg-2">
              <h5 className="text-white">More Information</h5>
              <p className="text-white">
                <BsArrowRightSquareFill size={20} color="#ffffff" />
                <span className="px-2 ">
                  <Link to="/about-us" className="text-white">
                    About Us{" "}
                  </Link>
                </span>
              </p>

              <p className="text-white">
                <BsArrowRightSquareFill size={20} color="#ffffff" />
                <span className="px-2">
                  <Link to="/services" className="text-white">
                    Services{" "}
                  </Link>
                </span>
              </p>
              <p className="text-white">
                <BsArrowRightSquareFill size={20} color="#ffffff" />
                <span className="px-2">
                  <Link to="/rooms/book-now" className="text-white">
                    Rooms
                  </Link>
                </span>
              </p>

              <p className="text-white">
                <BsArrowRightSquareFill size={20} color="#ffffff" />
                <span className="px-2">
                  <Link to="/contact-us" className="text-white">
                    Contact Us
                  </Link>
                </span>
              </p>
            </div>
            <div className="col-lg-3 text-white">
              <h5>Contact Details</h5>

              <p className="">
                <MdOutlineLocationOn size={20} color="#ffffff" />
                <span className="px-3 text-white">
                  Paro Town, Paro, Bhutan{" "}
                </span>
              </p>
              <p className="">
                <BsTelephoneInboundFill size={20} color="#ffffff" />
                <span className="px-3 text-white">
                  +975 17165003 / +975 77249630
                </span>
              </p>

              <p className="">
                <MdMarkEmailRead size={20} color="#ffffff" />
                <span className="px-3 text-white">
                  hoteldorjilingreservation@gmail.com
                </span>
              </p>
            </div>

            <div className="col-lg-2">
              <img
                src={require("./../Img/Scan.jpg")}
                alt=""
                className="img-fluid"
                style={{ height: "" }}
              />
              <div className="row pt-3">
                <div className="col">
                  <Link to="https://www.instagram.com/dorjilinghotel/">
                  <img
                    src={require("./../Img/instagram.png")}
                    alt=""
                    className="img-fluid"
                    style={{ height: "50px" }}
                  />
               </Link>
               <Link to="https://www.facebook.com/hoteldorjiling">
                  <img
                    src={require("./../Img/facebook.png")}
                    alt=""
                    className="img-fluid px-2"
                    style={{ height: "50px" }}
                  />
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-lg-3">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14164.943358207782!2d89.4124168!3d27.4307599!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39e19decb0b37ef7%3A0xa65b4d80ec94e007!2sHotel%20Dorjiling!5e0!3m2!1sen!2sbt!4v1696403399493!5m2!1sen!2sbt"
                width="100%"
                height="250px"
                style={{ border: "0" }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>

        <div className="container text-center ">
          <hr />
          <p className="text-white">
            © The Hotel Dorjiling, All Rights Reserved. <br />
            Designed & Developed by
            <span className="text-blue"> Freelancer Bhutan</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
