import React from "react";
import TravelAgent from "../Forms/TravelAgent";
import { Link } from "react-router-dom";

const AdvantageBhutanTour = () => {
  return (
    <div>
      <section className="travel_website ">
        <div className="container  ">
          <div className="row ">
            <div className="col-lg-12 ppt100 mb-5">
              <h1 className="shadow">TO TRAVEL IS TO LIVE </h1>
              <p className="text-white">
                It's better see something once than to <br /> hear about it a
                thousand times
              </p>
              <br />
              <button className="main-btn">Get Started</button>
            </div>
          </div>
        </div>
      </section>

      <section className="abt">
        <div className="container">
          <div className="row">
            <div className="col-lg-9">
              <h2>Advantage Bhutan Tours</h2>
              <p className="line"></p>
              <p className="text-justify-l">
                ABT is a tour and travel company established in 2015 and
                registered with the Tourism council of Bhutan (TCB). Its office
                is located in Paro that is 6km drive from Paro International
                Airport, the only international airport in the country. The
                company was established with the objective of promoting ‘Bhutan’
                the last thriving Shangri-La.
              </p>
              <p className="text-justify-l">
                <b>Objective:</b> To promote tourism in Bhutan, which will have
                a positive impact towards fostering socio-economic development
                of Bhutan. The primary objective would be to generate employment
                opportunity, earning foreign currency and to create innovative
                tourism avenues.
              </p>
            </div>
            <div className="col-lg-3 px-3  text-center">
              <div className="bg-yellow p-5 rounded">
                <img
                  src={require("./../../Img/advantagebhutan.gif")}
                  alt=""
                  className="img-fluid  rounded bg-white p-3 "
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="team" class="team_wrapper">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 section-title text-center mb-5">
              <h3> Product /services: </h3>
              <p className="line2 text-center"></p>
              <p className="pt-3">
                ABT intends to focus on several activities of tour in Bhutan.{" "}
                <br />
                ABT would also consider the demand of its clients in terms of
                activities/ programs in Bhutan.
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-3 col-md-6 mb-4">
              <div class="card p-0 rounded-3">
                <img
                  src={require("./../../Img/abt-cultural.jpg")}
                  class="img-fluid rounded-3"
                />
                <div class="team-info">
                  <h5>Culrural Tour</h5>
                  <p>5 Days - 4 Nights</p>
                  <ul class="social-network">
                    <li>Customiziable</li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 mb-4">
              <div class="card p-0 rounded-3">
                <img
                  src={require("./../../Img/abt-bird-watching.jpg")}
                  class="img-fluid rounded-3"
                />
                <div class="team-info">
                  <h5>Bird Watching Tour</h5>
                  <p>3 Days - 2 Nights</p>
                  <ul class="social-network">
                    <li>Customiziable</li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 mb-4">
              <div class="card p-0 rounded-3">
                <img
                  src={require("./../../Img/abt-festival.jpg")}
                  class="img-fluid rounded-3"
                />
                <div class="team-info">
                  <h5>Festivals  Tour</h5>
                  <p>8 Days - 7 Nights</p>
                  <ul class="social-network">
                    <li>Customiziable</li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 mb-4">
              <div class="card p-0 rounded-3">
                <img
                  src={require("./../../Img/abt-trekking.jpg")}
                  class="img-fluid rounded-3"
                />
                <div class="team-info">
                  <h5>Trekking</h5>
                  <p>4 Days - 3 Nights</p>
                  <ul class="social-network">
                    <li>Customiziable</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row">
            <div className="col lg-12 bg-blue rounded">
              <div className="row p-5">
                <div className="col-lg-5">
                  <h3 className="text-white">
                    Ready To Travel with Us <br /> On Your Next Trip?
                  </h3>
                  <br />

                  <button
                    type="button"
                    class="main-btnl "
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop"
                  >
                    Book Now
                  </button>

                  <div
                    class="modal fade"
                    id="staticBackdrop"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    tabindex="-1"
                    aria-labelledby="staticBackdropLabel"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog modal-dialog-centered">
                      <div class="modal-content p-4">
                    

                        <div class="modal-header">
        <h3 class="modal-title" id="staticBackdropLabel"> Book Now <br /> Advantage Bhutan Tour</h3> <br />
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body bg-blue rounded">
      <TravelAgent />
      </div>
    
                      </div>
                    </div>
                  </div>
                 
                </div>
                <div className="col-lg-7 pt-sm-5 sm-text-start pt-lg-0 text-end">
                  <h4 className="text-white">Contact Us </h4>
                  <p className="text-white">
                  Paro: Bhutan <br />
                  Email:   idorjiling@gmail.com <br />
                    Phone : +975
              17165003 – 77249630
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="text-center">
        <Link to="/">
          <button className="main-btn text-center"> GO TO HOME</button>
        </Link>
      </section>
    </div>
  );
};

export default AdvantageBhutanTour;
