import React from "react";
import { Link } from "react-router-dom";

export const ContactUsThankYou = () => {
  return (
    <div>
      <section className="">
        <div className="container mt-5 mb-5">
          <div className="row text-center p-5 ">
            <h1 className="fw-bold mb-5">
              
              <span className="text-yellow">Thank</span> You
            </h1>
            <h4>Your inquiry has been submitted </h4>
            <p className=" fs-6 ">
              You can expect a response within 24 hours, sent via email
            </p>
            <p>
              If this is an urgent inquiry, please WhatsApp or Phone at +975
              17165003 – 77249630
            </p>
            <div className="col-12 mt-5">
              <Link to="/rooms/book-now">
                <button className="main-btn ">
                  
                  Checkout our rooms
                </button>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
