import React from "react";
import TwinDeluxeForm from "./Forms/TwinDeluxeForm";

const BookTwinDeluxe = () => {
  return (
    <div>
      {" "}
      <div className="container p-5">
        <p>Room Booking for</p>
        <h1 className="text-blue">
          <span className="text-yellow">Twin</span> DELUXE{" "}
        </h1>
        <div className="row">
          <div className="col-lg-8 mb-4">
            <div class="p-3 border rounded ">
              <TwinDeluxeForm />
            </div>
          </div>

          <div class="col">
              <div class="p-4 rooms_wrapper rounded">
              <h4>Facilities in the rooms:</h4>
              <hr />

              <table>
                <tr>
                  <td  className="border rounded p-2">FLAT SCREEN SMART TV [ 42INCH]</td>
                  <td  className="border rounded p-2" >Yes</td>
                </tr>
                <tr>
                  <td  className="border rounded p-2">KING SIZE [SQUARE MATTRESS]
6/6.5FTS </td>
                  <td  className="border rounded p-2">Yes</td>
                </tr>
                <tr>
                  <td  className="border rounded p-2">BATHROOM  </td>
                  <td  className="border rounded p-2">Yes</td>
                </tr>
                <tr>
                  <td  className="border rounded p-2">AIR CONDITIONING/HEATING   </td>
                  <td  className="border rounded p-2">Yes</td>
                </tr>
                <tr>
                  <td  className="border rounded p-2">TEA/COFFEE STATIONS</td>
                  <td  className="border rounded p-2">Yes</td>
                </tr>
                <tr>
                  <td  className="border rounded p-2">SEPARATE STUDY TABLE  </td>
                  <td  className="border rounded p-2">Yes</td>
                </tr>
              
                <tr>
                  <td  className="border rounded p-2">MINI BAR  </td>
                  <td  className="border rounded p-2">Yes</td>
                </tr>
                <tr>
                  <td  className="border rounded p-2">SHOWER </td>
                  <td  className="border rounded p-2">Yes</td>
                </tr>
                <tr>
                  <td  className="border rounded p-2">TELEPHONE </td>
                  <td  className="border rounded p-2">Yes</td>
                </tr>
                <tr>
                  <td  className="border rounded p-2">HAIR DRYER  </td>
                  <td  className="border rounded p-2">Yes</td>
                </tr>
                <tr>
                  <td  className="border rounded p-2"> BATHROOM AMENITIES</td>
                  <td  className="border rounded p-2">Yes</td>
                </tr>
                <tr>
                  <td  className="border rounded p-2">INTERNET ACCESS </td>
                  <td  className="border rounded p-2">Yes</td>
                </tr>
                <tr>
                  <td  className="border rounded p-2"> PLUG TYPE</td>
                  <td  className="border rounded p-2">Yes</td>
                </tr>

                <tr>
                  <td  className="border rounded p-2"> TRIPLE BED OPTION</td>
                  <td  className="border rounded p-2">Yes</td>
                </tr>

                <tr>
                  <td  className="border rounded p-2"> DOUBLE BED OPTION</td>
                  <td  className="border rounded p-2">Yes</td>
                </tr>

                <tr>
                  <td  className="border rounded p-2"> WHEEL CHAIR ACCESSIBILITY</td>
                  <td  className="border rounded p-2">Yes</td>
                </tr>
              </table>

              </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default BookTwinDeluxe;
