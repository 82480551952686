import React from 'react'

const Logo = () => {
  return (
    <div>
        
      <img src={require('./../Img/Logo-Hotel-Dorjeeling.png')} alt="" />
    </div>
  )
}

export default Logo