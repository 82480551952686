import React from "react";
import { AiFillCheckSquare } from "react-icons/ai";

const TermsAndConditions = () => {
  return (
    <div>
      <div className="services_tac pt-5">
        <div className="container">
          <h3>Terms & Conditions</h3>
          <hr className="hr" />
          <p>
            <AiFillCheckSquare size={25} color="#1f2b3b" />
            10% BST (Bhutan Sales Tax) and 10% Service charge will be applicable
            <br />
            <AiFillCheckSquare size={25} color="#1f2b3b" />
            Extra Bed
            <br />
            <AiFillCheckSquare size={25} color="#1f2b3b" />
            Nu.800 Plus 20 % Tax
            <br />

            <AiFillCheckSquare size={25} color="#1f2b3b" />
            Promotional Rates are available
            <br />
            <AiFillCheckSquare size={25} color="#1f2b3b" />
            The check in and check out time is 12 noon.
            <br />
            <AiFillCheckSquare size={25} color="#1f2b3b" />
            Late Check out Policy:<br />The late checkout charges will be applicable as follows:
            <ul>
              <li>12 pm – 2 pm we will grant an extension without charge if requested in advance, subject to availability. </li>
              <li>Beyond 6 pm full night charge. </li>
            </ul>
            <AiFillCheckSquare size={25} color="#1f2b3b" />
            Early Check in Policy: <br />Early check in charge will be applicable as follows:
            <ul>
              <li>All arrivals prior to 6 am are billed from the previous night.</li>
            </ul>
            <AiFillCheckSquare size={25} color="#1f2b3b" />
            Infant/Child Policy:
            <ul>
              <li>Children under 12 years and below sharing room with parent without extra bed, FOC on rooms and breakfast.</li>
            </ul>
            <AiFillCheckSquare size={25} color="#1f2b3b" />
            Reservation and Cancellation Policy:
            <ul>
              <li>Reservations are on a first come first serve basis.</li>
            </ul>
            <br />
          
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
