import React from 'react'
import OtherHeader from '../components/OtherHeader'
import Footer from '../components/Footer'

const TravelLayouts = ({children}) => {
  return (
    <div>
       <OtherHeader />
        {children}

        <Footer />
    </div>
  )
}

export default TravelLayouts