import React from "react";

const AboutUs = () => {
  return (
    <div>
      <section id="about" class="about_wrapper abou-us-4">
        <div className="container pt-5">
          <div className="row pt-5">
            <h4 className="text-center about-heading">Hotel Dorjiling</h4>
            <h1 className="text-center text-white">About Us</h1>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row flex-lg-row ">
            <div className="col-lg-7  ">
              <h1>
                Welcome to <br />
                <span className="text-yellow">Hotel Dorjiling </span>
              </h1>
              <p className="text-justify-l">
                Nestled in the heart of the enchanting town of Paro, Hotel
                Dorjiling stands as a beacon of comfort and sophistication
                amidst the historical wonders and sacred sites that define this
                Bhutanese gem. Just a mere 10-minute drive from the Paro
                Airport, the only international gateway to Bhutan, our hotel's
                strategic location ensures convenience and accessibility for all
                our guests. <br />
                <br />
                Our collection of seventeen meticulously designed rooms,
                including Deluxe, Superior, and Standard categories, boasts
                tastefully furnished interiors adorned with exquisite decor. The
                result is an ambiance that is not only pleasing to the eyes but
                also exudes warmth and comfort, promising a serene retreat after
                a day of exploration.
                <br />
                <br />
                At Hotel Dorjiling, our commitment is unwavering when it comes
                to delivering impeccable service to our cherished guests. We
                take pride in our dedication to making your stay with us an
                unforgettable journey, one that will linger in your memories as
                a testament to our hospitality and the beauty of Bhutan. Your
                satisfaction is our utmost priority, ensuring that your time
                here becomes a cherished chapter in the story of your life.
              </p>
            </div>
            <div className="col-lg-5 mb-4 mb-lg-0 ps-lg-4 ">
              
              <h4 className="mt-4 text-yellow">Mission</h4>
              <p>
                To exceed the expectation of our guests, inspire and reward our
                associates.
              </p>
              <hr />
              <h4 className="text-yellow">Vision</h4>
              <p className="text-justify-l ">
            
                Local yet international taste, 3 star hotel offering consistent
                products and services in key business and leisure destinations.
                Our growth will be achieved through hotel ownership and
                management. We will be known for our innovation, superb service.
              </p>
              <hr />

              <h4 className="text-yellow">Objective</h4>
              <p> Generate customer satisfaction which is above 90 percent.</p>
              <hr />
            </div>
          </div>
        </div>
      </section>

     

      <section id="gallery" class=" rooms_wrapper">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 section-title text-center mb-5">
              <h5>Best Pictures Of Our Hotel</h5>
              <h3>Our Gallery</h3>
            </div>
          </div>
          <div class="row g-0">
            <div class="col-lg-3 col-md-6 gallery-item">
              <img src={require("./../Img/Hotel-Dorjeeling-chair.jpg")} class="img-fluid w-100" />
              <div class="gallery-item-content">
              </div>
            </div>
            <div class="col-lg-3 col-md-6 gallery-item">
              <img src={require("./../Img/Hotel-Dorjeeling-ghallery-restaurant.jpg")} class="img-fluid w-100" />
              <div class="gallery-item-content">sd</div>
            </div>
            <div class="col-lg-3 col-md-6 gallery-item">
              <img src={require("./../Img/Hotel-Dorjeeling-rooms.jpg")} class="img-fluid w-100" />
              <div class="gallery-item-content"></div>
            </div>
            <div class="col-lg-3 col-md-6 gallery-item">
              <img src={require("./../Img/Hotel-Dorjeeling-lama.jpg")} class="img-fluid w-100" />
              <div class="gallery-item-content"></div>
            </div>
            <div class="col-md-6 gallery-item">
              <img src={require("./../Img/Hotel-Dorjeeling-resevations.jpg")} class="img-fluid w-100" />
              <div class="gallery-item-content">
              </div>
            </div>
            <div class="col-md-6 gallery-item">
              <img src={require("./../Img/Hotel-Dorjeeling-lobby.jpg")} class="img-fluid w-100" />
              <div class="gallery-item-content">
              </div>
            </div>
          </div>
        </div>
      </section>

      
    </div>
  );
};

export default AboutUs;
