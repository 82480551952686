import React from "react";
import { Link } from "react-router-dom";
import { FiWifi } from "react-icons/fi";
import { MdRestaurant } from "react-icons/md";
import { PiUsersFourFill } from "react-icons/pi";
import { GrLounge } from "react-icons/gr";

// import function to register Swiper custom elements
import { register } from "swiper/element/bundle";
// register Swiper custom elements
register();

const Home = () => {
  return (
    <>
      <swiper-container
        slides-per-view="1"
        speed="500"
        loop="true"
        css-mode="true"
      >
        {/* <!-- lazy="true" attribute will automatically render the prelaoder element --> */}
        <swiper-slide lazy="true">
          <img
            src={require("./../Img/Hotel-Dorjeeling.jpg")}
            loading="lazy"
            className="img-fluid"
          />
        </swiper-slide>
        <swiper-slide lazy="true">
          <img
            src={require("./../Img/bed.jpg")}
            loading="lazy"
            className="img-fluid"
          />
        </swiper-slide>
        <swiper-slide lazy="true">
          <img
            src={require("./../Img/Hotel-Dorjeeling.jpg")}
            loading="lazy"
            className="img-fluid"
          />
        </swiper-slide>
        ...
      </swiper-container>

      {/* <section id="home" className="banner_wrapper p-0">
        <div className="container-fluid bg-blue ">
          <div className="container booking-area">
          <h1 className="text-center text-white">
                Welcome to  
                <span className="text-yellow"> Hotel Dorjee - Ling </span>
              </h1>
          </div>
        </div>


      </section> */}

      <section id="about-usd">
        <div className="container">
          <div className="row flex-lg-row flex-column-reverse ">
            <div className="col-lg-7  ">
              <h1 className="">
                Welcome to
                <span className="text-yellow"> Hotel Dorjiling </span>
              </h1>

              <p className="text-justify-l">
                Hotel Dorjiling, nestled in the enchanting town of Paro, is a
                destination of choice for tourists seeking a comfortable stay.
                Paro, renowned for its historical significance and numerous
                sacred sites, provides the perfect backdrop for our
                establishment. Located just a short 10-minute drive from Paro
                Airport, the sole international gateway to Bhutan, our hotel
                offers both convenience and accessibility.
                <br /> <br />
                At Hotel Dorjiling, we seamlessly merge contemporary themes
                with the rich tapestry of Bhutanese architecture, resulting in a
                unique and inviting atmosphere. As a distinctive 3-star boutique
                hotel, we proudly stand as a beacon of warm hospitality and
                unwavering commitment to exceeding the expectations of our
                cherished guests.
              </p>

              <Link to="/about-us" className="main-btn mt-4">
                Explore More
              </Link>
            </div>
            <div className="col-lg-5 mb-4 mb-lg-0 ps-lg-4 text-center">
              <img
                src={require("./../Img/Hotel-Dorjeeling-About-Us.png")}
                className="img-fluid"
                alt="About Us"
              />
            </div>
          </div>
        </div>
      </section>

      <section id="rooms" className="rooms_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 section-title text-center mb-5">
              <h5>AVAILABLE ROOMS</h5>
              <h1 className="text-yellow">Accommodations</h1>
              <div className="container">
                <p>
                  We provide a variety of upscale and contemporary deluxe rooms
                  that blend modern and traditional Bhutanese aesthetics. Our
                  deluxe rooms are thoughtfully designed to ensure your comfort,
                  boasting luxurious bedding, contemporary furnishings, and
                  refined decorations
                </p>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="col-lg-6 p-4">
              <div className="room-type1 p-4 p-lg-5">
                <div className="bg-whitee  rounded  p-2 pt-4 pb-4  p-lg-5 rooms-m ">
                  <h4 className="text-yellow ">King Deluxe</h4> <br />
                  <Link
                    className="main-btn border-white text-white "
                    to="/rooms/king-deluxe"
                  >
                    Room Info
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-lg-6 p-4">
              <div className="room-type2 p-4 p-lg-5">
                <div className="bg-whitee  rounded p-2 pt-4 pb-4 p-lg-5 rooms-m">
                  <h4 className="text-yellow ">Twin Deluxe</h4> <br />
                  <Link
                    className="main-btn border-white text-white "
                    to="/rooms/twin-deluxe"
                  >
                    Room Info
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="travel" className="travel_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 section-title  mb-5">
              <h5> Experience the beauty and serenity of Bhutan through</h5>
              <h2 className="text-yellow">Advantage Bhutan Tours</h2>
              <div className="">
                <p className="text-justify-l">
                  The company was established with the objective of promoting
                  ‘Bhutan’ the last thriving Shangri-La. ABT intends to focus on
                  several activities of tour in Bhutan viz: cultural and
                  spiritual, bird watching, festivals and treks. Besides the
                  listed activities, ABT would also consider the demand of its
                  clients in terms of activities/ programs in Bhutan.
                </p>

                <Link to="/advantage-bhutan-tours" className="main-btn mt-4">
                  Discover More{" "}
                </Link>
              </div>
            </div>

            <div className="col-lg-8 section-title text-center mb-5">
              <div className="takshang">
                <img
                  src={require("./../Img/Advantage-bhutan-tours.jpg")}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="blog" class="messagefrommanagement pb-0">
        <div class="container-fluid ">
          <div class="row bg-yellow">
            <div className="col-lg-6 col-md-6 background"></div>
            <div className="col-lg-6 col-md-6 p-5">
              <h4>Message from Management</h4>
              <p>
                {" "}
                We take pride in announcing that our executives & staff go much
                beyond what other commercial organizations do for tourists as
                our aim is to make more friends than commercial gains...
              </p>

              <Link to="/contact-us" className="side-btn mt-4">
                Contact Us
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section id="services" className="">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 section-title text-center mb-3">
              <h5> PROFESSIONALS</h5>
              <h1 className="text-yellow">Services</h1>
            </div>

            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 text-center">
                <FiWifi size={50} color="#1f2b3b" />
                <h4 className="text-center mt-2">Free WIFI</h4>
              </div>

              <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 text-center">
                <MdRestaurant size={50} color="#1f2b3b" />
                <h4 className="text-center mt-2">Restaurant and Bar</h4>
              </div>

              <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 text-center">
                <PiUsersFourFill size={50} color="#1f2b3b" />
                <h4 className="text-center mt-2">Conference hall</h4>
              </div>

              <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 text-center">
                <GrLounge size={50} color="#1f2b3b" />
                <h4 className="text-center mt-2">Lounge</h4>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="rooms_wrapper testimonails">
        <div className="container">
          <div class="row d-flex justify-content-center">
            <div class="col-md-10 col-xl-8 text-center">
              <h3 class="mb-4">Testimonials</h3>
              <p class="mb-4 pb-2 mb-md-5 pb-md-0">
                What our valued Guest seamlessly
                <hr />
              </p>
            </div>
          </div>

          <div class="row text-center d-flex align-items-stretch">
            <div class="col-md-4 mb-5 mb-md-0 d-flex align-items-stretch">
              <div class="card testimonial-card">
                <div class="card-up"></div>
                <div class="avatar mx-auto bg-white">
                  <img
                    src={require("./../Img/Ishita.png")}
                    class="rounded-circle img-fluid"
                  />
                </div>
                <div class="card-body">
                  <h4 class="mb-4">Ishita Bhattacharya</h4>
                  <hr />
                  <p class="dark-grey-text mt-4">
                    <i class="fas fa-quote-left pe-2"></i>Stayed here for 2 days
                    in Paro. Situated in main market. All the tourist sites
                    within short distance. Friendly staff. 
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-4 mb-5 mb-md-0 d-flex align-items-stretch">
              <div class="card testimonial-card">
                <div class="card-up"></div>
                <div class="avatar mx-auto bg-white">
                  <img
                    src={require("./../Img/Rajni-Kandali.png")}
                    class="rounded-circle img-fluid"
                  />
                </div>
                <div class="card-body">
                  <h4 class="mb-4">Rajni Kandalli</h4>
                  <hr />
                  <p class="dark-grey-text mt-4">
                    <i class="fas fa-quote-left pe-2"></i>I had visited Bhutan
                    with one of my friend in September 2023. We found Dorji ling
                    one of the best hotels to stay in Paro. All the hotel staff
                    are women and very humble and kind ladies.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-4 mb-0 d-flex align-items-stretch">
              <div class="card testimonial-card">
                <div class="card-up"></div>
                <div class="avatar mx-auto bg-white">
                  <img
                    src={require("./../Img/Karuna-Ramteke.png")}
                    class="rounded-circle img-fluid"
                  />
                </div>
                <div class="card-body">
                  <h4 class="mb-4">Karuna Ramteke</h4>
                  <hr />
                  <p class="dark-grey-text mt-4">
                    <i class="fas fa-quote-left pe-2"></i>Very good place to
                    stay in Paro..big rooms and all amenities also the staff
                    girls working there are very good .hospitality too good
                    ..very courteous polite they were taking special care of all
                    the guests..
                  </p>
                </div>
              </div>
            </div>
           

          </div>

          <div className="row pt-4">
            <div className="col-12 text-center">
              <Link to="https://www.google.com/travel/search?q=hotel%20dorjiling%20bhutan&g2lb=2502548%2C2503771%2C2503781%2C4258168%2C4270442%2C4284970%2C4291517%2C4308226%2C4597339%2C4757164%2C4814050%2C4874190%2C4893075%2C4924070%2C4965990%2C4990494%2C72248050%2C72248052%2C72298667%2C72302247%2C72317059%2C72379816%2C72385362%2C72390435%2C72404816%2C72407748%2C72407753&hl=en-BT&gl=bt&ssta=1&ts=CAEaRwopEicyJTB4MzllMTlkZWNiMGIzN2VmNzoweGE2NWI0ZDgwZWM5NGUwMDcSGhIUCgcI5w8QChgfEgcI5w8QCxgBGAEyAhAA&qs=CAEyFENnc0loOERUNUk2dzA2Mm1BUkFCOAJCCQkH4JTsgE1bpkIJCQfglOyATVum&ap=ugEHcmV2aWV3cw&ictx=1&sa=X&ved=0CAAQ5JsGahcKEwiAu4nGtZ6CAxUAAAAAHQAAAAAQDQ">
                           <button className="main-btn btn-small ">View All</button>
                           </Link>
 
            </div>
          
          </div>
        </div>
      </section>

      <section id="gallery" class="gallery_wrapper">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 section-title text-center mb-5">
              <h5>Best Pictures Of Our Hotel</h5>
              <h3>Our Gallery</h3>
            </div>
          </div>
          <div class="row g-0">
            <div class="col-lg-3 col-md-6  gallery-item">
              <img
                src={require("./../Img/Hotel-Dorjeeling-chair.jpg")}
                class="img-fluid w-100"
              />
              <div class="gallery-item-content"></div>
            </div>
            <div class="col-lg-3 col-md-6 gallery-item">
              <img
                src={require("./../Img/Hotel-Dorjeeling-ghallery-restaurant.jpg")}
                class="img-fluid w-100"
              />
              <div class="gallery-item-content">sd</div>
            </div>
            <div class="col-lg-3 col-md-6 gallery-item">
              <img
                src={require("./../Img/Hotel-Dorjeeling-rooms.jpg")}
                class="img-fluid w-100"
              />
              <div class="gallery-item-content"></div>
            </div>
            <div class="col-lg-3 col-md-6 gallery-item">
              <img
                src={require("./../Img/Hotel-Dorjeeling-lama.jpg")}
                class="img-fluid w-100"
              />
              <div class="gallery-item-content"></div>
            </div>
            <div class="col-md-6 gallery-item">
              <img
                src={require("./../Img/Hotel-Dorjeeling-resevations.jpg")}
                class="img-fluid w-100"
              />
              <div class="gallery-item-content"></div>
            </div>
            <div class="col-md-6 gallery-item">
              <img
                src={require("./../Img/Hotel-Dorjeeling-lobby.jpg")}
                class="img-fluid w-100"
              />
              <div class="gallery-item-content"></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
