import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
const TwinDeluxeForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [adult, setAdult] = useState("");
  const [child, setChild] = useState("");
  const [rooms, setRooms] = useState("");
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState("");
  const [checkin, setCheckIn] = useState("");
  const [checkout, setcheckOut] = useState("");
  const [special, setSpecial] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = () => {
    const data = new URLSearchParams();
    setIsLoading(true);
    data.append("name", name);
    data.append("subject", "Room Booking - Twin Deluxe");
    data.append("email", email);
    data.append("phone", phone);
    data.append("adult", adult);
    data.append("child", child);
    data.append("rooms", rooms);
    data.append("address", address);
    data.append("country", country);
    data.append("checkin", checkin);
    data.append("checkout", checkout);
    data.append("special", special);
    data.append("receiver_email", process.env.REACT_APP_RECEIVER_MAIL);
    fetch(process.env.REACT_APP_MAIL_BASE_URL + "/gmailsmtp/twin-deluxe.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Access-Control-Request-Headers": "*",
        "Access-Control-Request-Method": "*",
      },
      body: data,
    })
      .then((res) => {
        setIsLoading(false);
        navigate("/booking-form/submitted");
      })
      .catch((e) => console.log(e));
  };

  return (
    <div>
      <div className="row king-deluxe-form-page">
        <h3 className="text-blue mb-3"> Enter your Details</h3>

        <div className="mb-4 col-lg-12">
          <p
            for="exampleFormControlInput1"
            class="form-label text-yellow fs-5 "
          >
            Name
          </p>
          <input
            id="name"
            class="form-control"
            type="text"
            aria-label="default input example"
            name="name"
            onChange={(e) => setName(e.target.value)}
          />
        </div>

        <div className="mb-3 col-lg-6">
          <p
            for="exampleFormControlInput1"
            class="form-label text-yellow fs-5 "
          >
            Email address
          </p>
          <input
            type="email"
            class="form-control"
            id="exampleFormControlInput1"
            name="email"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        <div className="mb-3 col-lg-6">
          <p
            for="exampleFormControlInput1"
            class="form-label text-yellow fs-5 "
          >
            Phone Number
          </p>

          <input
            id="first-name"
            class="form-control"
            type="number"
            aria-label="default input example"
            name="Phone_number"
            onChange={(e) => setPhone(e.target.value)}
          />
        </div>
        <div className="mb-4 col-lg-4">
          <p
            for="exampleFormControlInput1"
            class="form-label text-yellow fs-5 "
          >
            Number of Adult
          </p>
          <select
            class="form-select"
            aria-label="Default select example"
            name="number_of_adult"
            onChange={(e) => setAdult(e.target.value)}
          >
            <option value="">Select One</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
            <option value="4">Four</option>
            <option value="5">Five</option>
          </select>
        </div>

        <div className="mb-4 col-lg-4">
          <p
            for="exampleFormControlInput1"
            class="form-label text-yellow fs-5 "
          >
            Number of Children
          </p>
          <select
            class="form-select"
            aria-label="Default select example"
            name="number_of_children"
            onChange={(e) => setChild(e.target.value)}
          >
          
            <option value="">Select One</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
            <option value="4">Four</option>
            <option value="5">Five</option>
          </select>
        </div>

        <div className="mb-4 col-lg-4">
          <p
            for="exampleFormControlInput1"
            class="form-label text-yellow fs-5 "
          >
            Number of Rooms
          </p>
          <select
            class="form-select"
            aria-label="Default select example"
            name="number_of_rooms"
            onChange={(e) => setRooms(e.target.value)}
          >
          
            <option value="">Select One</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
            <option value="4">Four</option>
            <option value="5">Five</option>
          </select>
        </div>
        <div className="mb-4 col-lg-6">
          <p
            for="exampleFormControlInput1"
            class="form-label text-yellow fs-5 "
          >
            Address
          </p>
          <input
            id="Address"
            class="form-control"
            type="text"
            aria-label="default input example"
            name="address"
            onChange={(e) => setAddress(e.target.value)}
          />
        </div>

        <div className="mb-4 col-lg-6">
          <p
            for="exampleFormControlInput1"
            class="form-label text-yellow fs-5 "
          >
            Country
          </p>
          <input
            id="first-name"
            class="form-control"
            type="text"
            aria-label="default input example"
            name="country"
            onChange={(e) => setCountry(e.target.value)}
          />
        </div>

        <div className="mb-4 col-lg-6">
          <p
            for="exampleFormControlInput1"
            class="form-label text-yellow fs-5 "
          >
            Check In
          </p>
          <input
            type="date"
            className="form-control"
            placeholder="Date"
            name="check_in"
            onChange={(e) => setCheckIn(e.target.value)}
          />
        </div>

        <div className="mb-4 col-lg-6">
          <p
            for="exampleFormControlInput1"
            class="form-label text-yellow fs-5 "
          >
            Check Out
          </p>
          <input
            type="date"
            className="form-control"
            placeholder="Date"
            name="check_out"
            onChange={(e) => setcheckOut(e.target.value)}
          />
        </div>

        <div className="mb-4 col-lg-12">
          <p
            for="exampleFormControlInput1"
            class="form-label text-yellow fs-5 "
          >
            Special request
          </p>
          <textarea
            class="form-control"
            id="exampleFormControlTextarea1"
            rows="3"
            name="message"
            onChange={(e) => setSpecial(e.target.value)}
          ></textarea>
        </div>

        <div className="col-6">
          <button
            onClick={handleSubmit}
            disabled={isLoading}
            className="main-btnl text-end"
          >
          
            {isLoading ? "Submitting" : "Book Now"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default TwinDeluxeForm;
