import React from 'react'
import {Link} from 'react-router-dom';

const OtherHeader = () => {
  return (
    <div>
          <header className="oheader_wrapper">
        <nav className="navbar navbar-expand-lg ">
          <div className="container">
            <Link to="/">
              <img
                src={require("./../Img/Logo-Hotel-Dorjeeling.png")}
                className="img-fluid"
                alt="logo"
              />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <i className="fas fa-stream navbar-toggler-icon"></i>
            </button>

            <div
              className="collapse navbar-collapse justify-content-end"
              id="navbarNav"
            >
              <ul className="navbar-nav menu-navbar-nav">
                <li className="nav-item">
                  <Link className="nav-link active" aria-current="page" to="/">
                    Home
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>

      </header>

    </div>
  )
}

export default OtherHeader