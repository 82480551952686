import React from "react";

import { Link } from "react-router-dom";

const KingBookingTemplate = () => {
  return (
    <div>
      <section className="bg-blue">
        <div className="container p-5">
          <div className="row m-5">
            <header className="bg-yellow rounded-top p-2">
              <nav className="navbar navbar-expand-lg ">
                <div className="container">
                  <Link to="/">
                    <img
                       src={require("./../../../Img/Logo-Hotel-Dorjeeling.png")}
                      className="img-fluid"
                      alt="logo"
                    />
                  </Link>

                  <div className="collapse navbar-collapse justify-content-start px-5">
                    <h3>
                      Welcome to{" "}
                      <span className="text-white">Hotel Dorjeeling</span>
                    </h3>
                  </div>
                </div>
              </nav>
            </header>

            <div className="col-lg-12 bg-white p-4">
              <span className="p">Room Booked</span>
              <span className="h2"> -King Deluxe</span>
              <hr />
              <h3>Customer Details</h3>
              <table class="table table-striped ">
                <thead>
                  <tr>
                    <th scope="row">Name:</th>
                    <td>Nima Moktan</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>Email ID:</th>
                    <td>nmoktan@gmail.com</td>
                  </tr>
                  <tr>
                    <th scope="row">Phone Number:</th>
                    <td>17609812</td>
                  </tr>
                  <tr>
                    <th scope="row">Number of Adult:</th>
                    <td colspan="2">One</td>
                  </tr>
                  <tr>
                    <th scope="row">Number of Children:</th>
                    <td colspan="2">none</td>
                  </tr>

                  <tr>
                    <th scope="row">Number of Rooms:</th>
                    <td colspan="2">One</td>
                  </tr>

                  <tr>
                    <th scope="row">Address:</th>
                    <td colspan="2">chamzamtok, Thimphu</td>
                  </tr>

                  <tr>
                    <th scope="row">Country:</th>
                    <td colspan="2">Bhutan</td>
                  </tr>

                  <tr>
                    <th scope="row">Special Request:</th>
                    <td colspan="2">
                      {" "}
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                      Autem, nulla molestias, quas exercitationem consequatur
                      architecto! Deserunt modi eaque unde magni commodi
                      consectetur fugit ipsa sapiente laudantium voluptate
                      provident ut odit porro minima id temporibus blanditiis
                      autem. Nostrum, pariatur?{" "}
                    </td>
                  </tr>
                </tbody>
              </table>
            
            </div>

            <div className="footer bg-yellow rounded-bottom pt-3 text-white">
           <p className="text-white text-center"> © The Hotel Dorjeeling, All Rights Reserved.</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default KingBookingTemplate;
