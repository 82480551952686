import React from "react";
import { Link } from "react-router-dom";
import { usePopper } from "react-popper";

const Header = () => {
  return (
    <div>
      <header className="header_wrapper">
        <nav className="navbar navbar-expand-lg ">
          <div className="container">
            <Link to="/">
              <img
                src={require("./../Img/Logo.png")}
                className="img-fluid"
                alt="logo"
              />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <i className="fas fa-stream navbar-toggler-icon"></i>
            </button>

            <div
              className="collapse navbar-collapse justify-content-end"
              id="navbarNav"
            >
              <ul className="navbar-nav menu-navbar-nav">
                <li className="nav-item">
                  <Link className="nav-link" aria-current="page" to="/">
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/about-us">
                    About Us
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/services">
                    Services
                  </Link>
                </li>
                <li class="nav-item dropdown">
                  <a
                    class="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Rooms
                  </a>
                  <ul class="dropdown-menu p-2" aria-labelledby="navbarDropdown">
                    <li>
                      <Link class="dropdown-item nav-link"  to="/rooms/king-deluxe">
                        King Deluxe
                      </Link>
                    </li>
                    <li>
                    <Link class="dropdown-item nav-link"  to="/rooms/twin-deluxe">
                        Twin Deluxe 
                        </Link>
                    </li>                        
                  </ul>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/contact-us">
                    Contact Us
                  </Link>
                </li>
                <li className="nav-item mt-3 mt-lg-0">
                  <Link className="main-btn" to="/rooms/book-now">
                    Book Now
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>

      </header>
    </div>
  );
};

export default Header;
