import React from "react";
import { Link } from "react-router-dom";

const TravelThankYou = () => {
  return (
    <div>
      <section className="vh-100">
        <div className="container mt-5 mb-5">
          <div className="row text-center p-5 ">
            <div className="col-lg-3"></div>
            <div className="col-lg-6">
              <h1 className="fw-bold mb-2">
                <span className="text-yellow">Thank </span>You{" "}
              </h1>
              <h2 className=" mb-2">For choosing Advantage Bhutan Tour</h2>
              <p>
                We're excited to host you and ensure a comfortable stay. Your
                trust means a lot. Safe travels!. You wil be contacted via{" "}
                <span className="text-yellow h5">email</span> with more details
              </p>

              <div className="col-12 mt-5">
                <Link to="/">
                  <button className="main-btn ">Go to Home</button>
                </Link>
              </div>
            </div>
          <div className="col-lg-3"></div>
        </div>
        </div>
      </section>
    </div>
  );
};

export default TravelThankYou;
