import React, {useState} from "react";
import { Link, useNavigate } from "react-router-dom";
const TravelAgent = () => {
  const[name, setName] = useState("");
  const[email, setEmail] = useState("");
  const[phone, setPhone] = useState("");
  const[checkin, setCheckIn] = useState("");
  const[checkout, setcheckOut] = useState("");
  const[special, setSpecial] = useState("");
  const [isLoading, setIsLoading] = useState(false)

  const navigate = useNavigate();

  const handleSubmit = () => {
    const data = new URLSearchParams();
    setIsLoading(true)
    data.append('name', name);
    data.append('subject', "ABT - Hotel Dorjee-Ling");
    data.append('email', email);
    data.append('phone', phone);
    data.append('checkin', checkin);
    data.append('checkout', checkout);
    data.append('special', special);
    data.append('receiver_email', process.env.REACT_APP_RECEIVER_MAIL);
    fetch(process.env.REACT_APP_MAIL_BASE_URL+'/gmailsmtp/travel-agent.php', 
    {
      method: "POST",
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        "Access-Control-Request-Headers": "*",
        "Access-Control-Request-Method": "*"
      },
      body: data
    }).then((res) => {
      setIsLoading(false)
      navigate('/advantage-bhutan-tours/form/submitted')

    }).catch((e)=> console.log(e))
  };

  

  return (
    <div>
      <div class="mb-3">
        <p for="name" class="form-label text-white">
          Name <span className="fs-6"> (required)</span>
        </p>
        <input
          class="form-control"
          type="text"
          aria-label="default input example"
          name="name"
          onChange={(e) => setName(e.target.value)}
        />
      </div>

      <div class="mb-3">
        <p for="name" class="form-label text-white">
          Email Id <span className="fs-6"> (required)</span>
        </p>
        <input
          class="form-control"
          type="email"
          aria-label="default input example"
          name="email"
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>

      <div class="mb-3">
        <p for="name" class="form-label text-white">
          Phone Number Id <span className="fs-6"> (required)</span>
        </p>
        <input
          class="form-control"
          type="number"
          aria-label="default input example"
          name="phone_number"
          onChange={(e) => setPhone(e.target.value)}
        />
      </div>

      <div className="row">
        <div className="mb-4 col-lg-6">
          <p
            for="exampleFormControlInput1"
            class="form-label text-yellow fs-5 "
          >
            Check In
          </p>
          <input
            type="date"
            className="form-control"
            placeholder="Date"
            name="checkin"
            onChange={(e) => setCheckIn(e.target.value)}
          />
        </div>

        <div className="mb-4 col-lg-6">
          <p
            for="exampleFormControlInput1"
            class="form-label text-yellow fs-5 "
          >
            Check Out
          </p>
          <input
            type="date"
            className="form-control"
            placeholder="Date"
            onChange={(e) => setcheckOut(e.target.value)}
          />
        </div>
      </div>

      <div className="mb-4 col-lg-12">
        <p for="exampleFormControlInput1" class="form-label text-yellow fs-5 ">
          Special request
        </p>
        <textarea
          class="form-control"
          id="exampleFormControlTextarea1"
          rows="3"
          name="message"
          onChange={(e) => setSpecial(e.target.value)}
        ></textarea>
      </div>

      <div className="col-lg-6">
            <button onClick={handleSubmit} disabled={isLoading} className="main-btnl text-end"> {isLoading ? 'Submitting' : 'Book Now'}</button>
        </div>
    </div>
  );
};

export default TravelAgent;
