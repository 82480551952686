import React from "react";
import { Link } from "react-router-dom";
import { FaMale } from "react-icons/fa";
import { FaChild } from "react-icons/fa";

import { AiFillCheckSquare } from "react-icons/ai";
import RoomAmenities from "./Room Amenities/RoomAmenities";
import TermsAndConditions from "./Room Amenities/TermsAndConditions";
import RoomCancellation from "./Room Amenities/RoomCancellation";

export const BookNow = () => {
  return (
    <div>
      <section id="" className="book-now">
        <div className="container pt-5">
          <div className="row pt-5">
            <h4 className="text-center about-heading">Hotel Dorjiling</h4>
            <h1 className="text-center text-white">Book Now</h1>
          </div>
        </div>
      </section>

      <section>
        <div className="container py-3">
          <p>We have 2 types of accommodation that suit your needs.</p>
          <h3> Select your accommodation</h3>

          <hr />
         

          <div className="row ">
            <div className="col-lg-6 pt-3">
              <div className="row rooms_wrapper m-0 g-0 ">
                <div className="col-12">
                  <img
                    src={require("./../Img/king-deluxe-room.jpg")}
                    alt=""
                    className="img-fluid rounded-top"
                  />
                </div>
                <div className="col-12 p-3 ">
                  <div className="row ">
                    <div className="col-12">
                      <h4>King Deluxe</h4>
                    </div>
                    <div className="col-6">
                      <p className="fs-6">
                        Room Capacity:
                        <br />
                        <span className="fs-6">2 Adult, 1 Child Room</span>
                        <br />
                      </p>
                    </div>
                    <div className="col-6 text-end px-4">
                      <h3>
                        BTN 2,400.00 <br />
                        <span className="fs-6">per room per night </span>
                      </h3>
                    </div>
                  </div>
                  <div className="row">
                    <hr />
                    <div className="col-5  flex">
                      <p className="text-decoration-none">
                        <Link to="/rooms/king-deluxe">
                          <p className="text-blue fw-bold"> Room Info</p>
                        </Link>
                      </p>
                    </div>
                    <div className="col-7 flex text-end">
                      <Link to="/rooms/book-king-deluxe">
                        <p className="main-btn text-end"> Book Now</p>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-sm-12 pt-3">
              <div className="row rooms_wrapper m-0 g-0  ">
                <div className="col-lg-12 col-sm-12">
                  <img
                    src={require("./../Img/Twin-Deluxe-room.jpg")}
                    alt=""
                    className="img-fluid rounded-top"
                  />
                </div>
                <div className="col-lg-12 p-3 border  ">
                  <div className="row ">
                    <div className="col-12">
                      <h4>Twin Deluxe</h4>
                    </div>
                    <div className="col-6">
                      <p className="fs-6">
                        Room Capacity:
                      
                        <br />
                        <span className="fs-6">2 Adult</span>
                        <br />
                      </p>
                    </div>
                    <div className="col-6 text-end px-4">
                      <h3>
                        BTN 2,200.00 <br />
                        <span className="fs-6">per room per night </span>
                      </h3>
                    </div>
                  </div>
                  <div className="row">
                    <hr />
                    <div className="col-5 flex">
                      <p className="text-decoration-none">
                        <Link to="/rooms/twin-deluxe">
                          <p className="text-blue fw-bold"> Room Info</p>
                        </Link>
                      </p>
                    </div>
                    <div className="col-7 flex text-end">
                      <Link to="/rooms/book-twin-deluxe">
                        <p className="main-btn text-end"> Book Now</p>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <RoomAmenities />
      <RoomCancellation />
      <TermsAndConditions />
    </div>
  );
};
