import React from "react";
import { MdOutlineLocationOn } from "react-icons/md";
import { BsTelephoneInboundFill } from "react-icons/bs";
import { BsTelephoneFill } from "react-icons/bs";
import { MdMarkEmailRead } from "react-icons/md";
import ContactUsForm from "./Forms/ContactUsForm";

const ContactUs = () => {
  return (
    <div>
      <section id="" className="contact-us-page">
        <div className="container pt-5">
          <div className="row pt-5">
            <h4 className="text-center about-heading">Hotel Dorjiling</h4>
            <h1 className="text-center text-white">Contact Us</h1>
          </div>
        </div>
      </section>

      <section>
        <div className="container ccontact">
          <div className="row g-5">
            <div className="col-lg-7 px-5">
              <h2 className="text-yellow">Contact Details</h2>
              <h4>Hotel Dorjiling </h4>
              <p className="">
                <MdOutlineLocationOn size={20} color="#1f2b3b" />{" "}
                <span className="px-2 "> Paro Town, Paro, Bhutan </span>
              </p>
              <p className="">
                <BsTelephoneInboundFill size={20} color="#1f2b3b" />{" "}
                <span className="px-2 "> +975 - 17165003 / 77249630</span>
              </p>
             
              <p className="">
                <MdMarkEmailRead size={20} color="#1f2b3b" />{" "}
                <span className="px-2"> idorjiling@gmail.com / 
 hoteldorjilingreservation@gmail.com
</span>
              </p>

              <h5 className="fst-italic">
                " We are committed to offer impeccable services to our valuable
                guests.It is our sincere commitment to make your stay one of the
                most unforgettable of a lifetime."
              </h5>
            </div>
            <div className="col-lg-5 bg-blue p-5 rounded">
              <ContactUsForm />
            </div>
          </div>
        </div>
      </section>

      <div className="container-fluid p-0 m-0">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14164.943358207782!2d89.4124168!3d27.4307599!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39e19decb0b37ef7%3A0xa65b4d80ec94e007!2sHotel%20Dorjiling!5e0!3m2!1sen!2sbt!4v1696403399493!5m2!1sen!2sbt"
          width="100%"
          height="450px"
          style={{ border: "0" }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};

export default ContactUs;
