import React from "react";
import { Link } from "react-router-dom";

import BedAndMeals from "./Room Amenities/BedAndMeals";
import RoomAmenities from "./Room Amenities/RoomAmenities";
import TermsAndConditions from "./Room Amenities/TermsAndConditions";
import RoomCancellation from "./Room Amenities/RoomCancellation";

export const TwinDeluxe = () => {
  return (
    <div>
      <swiper-container
        slides-per-view="1"
        speed="500"
        loop="true"
        css-mode="true"
      >
        {/* <!-- lazy="true" attribute will automatically render the prelaoder element --> */}
        <swiper-slide lazy="true">
          <img
            src={require("./../Img/Twin-Deluxe-room.jpg")}
            loading="lazy"
            className="img-fluid"
          />
        </swiper-slide>
      </swiper-container>

      <section>
        <div className="container">
          <div className="row">
            <h5 className="fs-5"> ROOM OVERVIEW</h5>
            <h1 className="text-yellow fw-bold ">
              Twin <span className="text-blue">Deluxe</span>{" "}
            </h1>
            <hr />
            <div className="col-lg-8">
              <p className="text-justify-l">
                Our Twin Deluxe rooms offer a spacious and inviting retreat for
                two, complete with twin beds for your comfort. Elegantly
                designed with modern amenities and a touch of Bhutanese charm,
                these rooms provide a tranquil atmosphere. Ideal for friends or
                family, they promise a delightful stay.
                <br />
                <br />
              </p>
              <table className="table table-success table-striped">
                <tr>
                  <td className="border rounded p-2">Room Size</td>
                  <td className="border rounded p-2">352 sqft </td>
                </tr>
                <tr>
                  <td className="border rounded p-2">Bed Size </td>
                  <td className="border rounded p-2">81x45 </td>
                </tr>
                <tr>
                  <td className="border rounded p-2">Total Rooms </td>
                  <td className="border rounded p-2">11 </td>
                </tr>
                <tr>
                  <td className="border rounded p-2">Bed Type </td>
                  <td className="border rounded p-2">Twin </td>
                </tr>
              </table>
              <br />
              <div className="flex">
                <div className="row g-0">
                  <div id="carouselExample" className="carousel slide">
                    <div className="carousel-inner">
                      <div className="carousel-item active">
                        <img
                          src={require("./../Img/Twin-Deluxe-Bed.jpg")}
                          className="d-block w-100"
                          alt="..."
                        />
                      </div>
                      <div className="carousel-item">
                        <img
                          src={require("./../Img/Hotel-Dorjiling-Scence.jpg")}
                          className="d-block w-100"
                          alt="..."
                        />
                      </div>
                      <div className="carousel-item">
                        <img
                          src={require("./../Img/Entrance.jpg")}
                          className="d-block w-100"
                          alt="..."
                        />
                      </div>
                    </div>
                    <button
                      className="carousel-control-prev"
                      type="button"
                      data-bs-target="#carouselExample"
                      data-bs-slide="prev"
                    >
                      <span
                        className="carousel-control-prev-icon"
                        aria-hidden="true"
                      ></span>
                      <span className="visually-hidden">Previous</span>
                    </button>
                    <button
                      className="carousel-control-next"
                      type="button"
                      data-bs-target="#carouselExample"
                      data-bs-slide="next"
                    >
                      <span
                        className="carousel-control-next-icon"
                        aria-hidden="true"
                      ></span>
                      <span className="visually-hidden">Next</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4  rounded ">
              <div className="bg-blue rounded p-4">
                <h4 className="text-yellow">Facilities Avaliable</h4>
                <br />
                <table className="text-white">
                  <tr>
                    <td className="border rounded p-2">
                      FLAT SCREEN SMART TV [ 42INCH]
                    </td>
                    <td className="border rounded p-2">Yes</td>
                  </tr>
                  <tr>
                    <td className="border rounded p-2">
                      KING SIZE [SQUARE MATTRESS] 6/6.5FTS{" "}
                    </td>
                    <td className="border rounded p-2">Yes</td>
                  </tr>
                  <tr>
                    <td className="border rounded p-2">BATHROOM </td>
                    <td className="border rounded p-2">Yes</td>
                  </tr>
                  <tr>
                    <td className="border rounded p-2">
                      AIR CONDITIONING/HEATING{" "}
                    </td>
                    <td className="border rounded p-2">Yes</td>
                  </tr>
                  <tr>
                    <td className="border rounded p-2">TEA/COFFEE STATIONS</td>
                    <td className="border rounded p-2">Yes</td>
                  </tr>
                  <tr>
                    <td className="border rounded p-2">
                      SEPARATE STUDY TABLE{" "}
                    </td>
                    <td className="border rounded p-2">Yes</td>
                  </tr>

                  <tr>
                    <td className="border rounded p-2">MINI BAR </td>
                    <td className="border rounded p-2">Yes</td>
                  </tr>
                  <tr>
                    <td className="border rounded p-2">SHOWER </td>
                    <td className="border rounded p-2">Yes</td>
                  </tr>
                  <tr>
                    <td className="border rounded p-2">TELEPHONE </td>
                    <td className="border rounded p-2">Yes</td>
                  </tr>
                  <tr>
                    <td className="border rounded p-2">HAIR DRYER </td>
                    <td className="border rounded p-2">Yes</td>
                  </tr>
                  <tr>
                    <td className="border rounded p-2"> BATHROOM AMENITIES</td>
                    <td className="border rounded p-2">Yes</td>
                  </tr>
                  <tr>
                    <td className="border rounded p-2">INTERNET ACCESS </td>
                    <td className="border rounded p-2">Yes</td>
                  </tr>
                  <tr>
                    <td className="border rounded p-2"> PLUG TYPE</td>
                    <td className="border rounded p-2">Yes</td>
                  </tr>

                  <tr>
                    <td className="border rounded p-2"> TRIPLE BED OPTION</td>
                    <td className="border rounded p-2">Yes</td>
                  </tr>

                  <tr>
                    <td className="border rounded p-2"> DOUBLE BED OPTION</td>
                    <td className="border rounded p-2">Yes</td>
                  </tr>

                  <tr>
                    <td className="border rounded p-2">
                      {" "}
                      WHEEL CHAIR ACCESSIBILITY
                    </td>
                    <td className="border rounded p-2">Yes</td>
                  </tr>
                </table>
                <br />
                <Link to="/rooms/book-twin-deluxe">
                  <button type="submit" className="main-btnl rounded-2 px-lg-3">
                    Book Now
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <RoomAmenities />
      <TermsAndConditions />
      <RoomCancellation />
    </div>
  );
};
