import './App.css';
import './Custom.css'

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import AboutUs from './Pages/AboutUs';
import Services from './Pages/Services';
import ContactUs from './Pages/ContactUs';
import KingDeluxe from './Pages/KingDeluxe';
import { TwinDeluxe } from './Pages/TwinDeluxe';
import { BookNow } from './Pages/BookNow';
import AdvantageBhutanTour from './Pages/TravelAgent/AdvantageBhutanTour';
import { ContactUsThankYou } from './Pages/Forms/ContactUsThankYou';
import BookKingDeluxe from './Pages/BookKingDeluxe';
import BookTwinDeluxe from './Pages/BookTwinDeluxe';
import BookingThankYou from './Pages/Forms/BookingThankYou';
import KingBookingTemplate from './Pages/Forms/FormTemplates/KingBookingTemplate';
import ContactUsTemplate from './Pages/Forms/FormTemplates/ContactUsTemplate';
import HotelsLayouts from './Layouts/HotelsLayouts';
import TravelLayouts from './Layouts/TravelLayouts';
import ScrollToTop from './ScrollToTop';
import TravelThankYou from './Pages/Forms/TravelThankYou';
import Logo from './Pages/Logo';




function App() {
  return (
   <>

<BrowserRouter>
<ScrollToTop />
<Routes>
  <Route path='/' element={<HotelsLayouts children={<Home />}/>}/>
  <Route path='/about-us' element={<HotelsLayouts children={<AboutUs />}/>}> </Route>
  <Route path='/services' element={<HotelsLayouts children={<Services />}/>}> </Route>
  <Route path='/rooms/king-deluxe' element={<HotelsLayouts children={<KingDeluxe />}/>}> </Route>
  <Route path='/rooms/book-king-deluxe' element={<TravelLayouts children={<BookKingDeluxe />}/>}> </Route>
  <Route path='/rooms/twin-deluxe' element={<HotelsLayouts children={<TwinDeluxe />}/>}> </Route>
  <Route path='/rooms/book-twin-deluxe' element={<HotelsLayouts children={<BookTwinDeluxe />}/>}> </Route>
  <Route path='/rooms/book-now' element={<HotelsLayouts children={<BookNow />}/>}> </Route>
  <Route path='/contact-us' element={<HotelsLayouts children={<ContactUs />}/>}> </Route>
  <Route path='/contact-us-form/submitted' element={<HotelsLayouts children={<ContactUsThankYou />}/>}> </Route>
  <Route path='/contact-us-form/template' element={<ContactUsTemplate />}> </Route>
  <Route path='/booking-form/submitted' element={<BookingThankYou />}> </Route>
  <Route path='/booking-form/king-deluxe-template' element={<KingBookingTemplate />}> </Route>
  <Route path='/advantage-bhutan-tours' element={<AdvantageBhutanTour />}> </Route>
  <Route path='/advantage-bhutan-tours/form/submitted' element={<TravelThankYou />}> </Route>
  <Route path='/logo' element={<Logo />}> </Route>
 
</Routes>
</BrowserRouter>


   </>
  );
}

export default App;
