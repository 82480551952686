import React from "react";

import { Link } from "react-router-dom";

import { BsCheckAll } from "react-icons/bs";

const Services = () => {
  return (
    <div>
      <section id="services" class="services_wrapper">
        <div className="container">
          <div className="row pt-5">
            <h4 className="text-center about-heading">Hotel Dorjiling</h4>
            <h1 className="text-center text-white">Services</h1>
          </div>
        </div>
      </section>

      <section id="title">
        <div className="container">
          <div className="row text-center">
            <h1>
              <span className="text-yellow">Explore Our </span>HOTEL SERVICES
            </h1>{" "}
            <br />
            <br />
            <p>
              Indulge in the epitome of comfort with our lavish accommodations,
              featuring a variety of room styles to suit your preferences.
              Delight in exquisite dining at our in-house restaurant, a lively
              gathering place for drinks and conversations. Take advantage of
              our concierge services to effortlessly plan your itinerary and
              enhance your experience in our city. Whether your visit is for
              business or leisure, our hotel provides all the amenities for a
              pleasant and enjoyable stay. Reserve your stay with us now to
              savor the pinnacle of luxury and comfort.
            </p>
          </div>
        </div>
      </section>

      <section id="services" className="service_service">
        <div className="container">
          <div className="row row flex-lg-row flex-column-reverse bg-white p-sm-5 p-lg-5 mb-5">
            <div className="col-lg-6 mb-5">
              <h2>ACCOMMODATION</h2>
              <p className="text-justify-l">
                Are you in search of a cozy and opulent accommodation for your
                upcoming journey? Our selection includes King Deluxe and Twin
                Deluxe rooms, meticulously crafted to enhance your stay. Each
                room is thoughtfully designed for your comfort and comes
                equipped with contemporary conveniences like air conditioning,
                Smart TVs, complimentary Wi-Fi, and plush beds.
              </p>
              <Link to="/rooms/book-now" className="main-btn mt-4">
                Explore More
              </Link>
            </div>

            <div className="col-lg-6 mb-4">
              <img
                src={require("./../Img/ACCOMMODATION.jpg")}
                alt=""
                className="img-fluid"
              />
            </div>
          </div>

          <div className="row bg-white p-sm-5 p-lg-5 mb-5">
            <div className="col-lg-6 mb-4 ">
              <img
                src={require("./../Img/Restaurant.jpg")}
                alt=""
                className="img-fluid"
              />
            </div>
            <div className="col-lg-6">
              <h2>Restaurant</h2>
              <p className="text-justify-l">
                We offer a wide range of cuisine options- In addition to the
                variety of local dishes, we also serve chinese, Indian and
                continental cuisine and features a great selection of your
                favourite dishes and set menus. <br />
                <br /> Most of the items served (rice, vegetables, and fruits)
                are purchased from local market. The food items are organically
                grown and harvested using traditional Bhutanese methods.
                <br />
                <br />
                <strong>Hours: 7am to 10 pm</strong> <br />
              </p>
            </div>
          </div>

          <div className="row flex-lg-row flex-column-reverse bg-white p-sm-5 p-lg-5 mb-5">
            <div className="col-lg-6 ">
              <h2>Bar</h2>
              <p className="text-justify-l">
                Our establishment takes pride in curating a diverse and enticing
                selection of both native and international beverages,
                accompanied by delectable snacks that are sure to tantalize your
                taste buds. <br />
                <br />
                Whether you're a connoisseur of local flavors or an adventurer
                seeking global tastes, our menu caters to every palate.
                <br />
                <br />
                <strong>Time: 2:00 PM -12:00 AM</strong>
              </p>
            </div>

            <div className="col-lg-6 mb-3">
              <img
                src={require("./../Img/Bar.jpg")}
                alt=""
                className="img-fluid"
              />
            </div>
          </div>

          <div className="row bg-white p-sm-5 p-lg-5 mb-5">
            <div className="col-lg-6 mb-3">
              <img
                src={require("./../Img/Business-Center.jpg")}
                alt=""
                className="img-fluid"
              />
            </div>
            <div className="col-lg-6 mb-5">
              <h2>Business Center</h2>
              <p className="text-justify-l">
                We provide seamless meeting and conference services including
                fully equipped meeting rooms to ensure the highest levels of
                creativity, convenience and efficiency for every business
                requirement and request. In addition to our perfectly appointed
                meeting rooms, we provide a technology-driven business centre
                designed to provide guests with comprehensive secretarial,
                meeting and ancillary services all expertly handled by our
                professional staff.
              </p>

              <Link to="/contact-us" className="main-btn mt-4">
                Contact Us
              </Link>
            </div>
          </div>

          <div className="row flex-lg-row flex-column-reverse bg-white p-sm-5 p-lg-5 mb-5">
            <div className="col-lg-6 mb-3">
              <h2> Concierge Services</h2>
              <p className="text-justify-l">
                Our dedicated concierge team is at your service around the
                clock, ready to help with any inquiries, be it related to
                dining, entertainment, or attractions. Comprising locals with
                deep-rooted knowledge of the area, they can provide assistance
                with transportation, arrange tours, and make restaurant
                reservations, ensuring your stay is both hassle-free and
                unforgettable.
              </p>
            </div>

            <div className="col-lg-6 mb-3">
              <img
                src={require("./../Img/CONCIERGE-SERVICES.jpg")}
                alt=""
                className="img-fluid"
              />
            </div>
          </div>

          <div className="row flex-lg-row  bg-white p-sm-5 p-lg-5 mb-5">

          <div className="col-lg-6 mb-3">
              <img
                src={require("./../Img/pick-and-drop.jpg")}
                alt=""
                className="img-fluid"
              />
            </div>
            <div className="col-lg-6 mb-3">
              <h2>Pick Up & Drop</h2>
              <p className="text-justify-l">
                At our establishment, we go the extra mile to ensure your
                convenience and comfort. To that end, we provide seamless
                pick-up and drop-off services at rates that are not only
                competitive but also designed to meet your budgetary
                preferences. Whether you're arriving at the airport, or any
                other location, our dependable transportation services are at
                your disposal.
              </p>
            </div>

           
          </div>
        </div>
      </section>
    </div>
  );
};

export default Services;
